import React from "react";

export default function Subscriptions() {
  return (
    <div className="subscriptions-container" id="subscriptions">
      <h1>Word vandaag nog lid</h1>
      <p>
        Bij aanvang van een abonnement krijg je een strikt persoonlijke badge
        waar je tijdens de openingsuren telkens mee kan komen trainen
      </p>
      <div className="subscriptions">
        <div className="sub">
          <h2>6 maanden abonnement</h2>
          <span className="cost">
            <h2>€ 200</h2>
          </span>
          <p>Inclusief voeding-en trainingsschema's op maat</p>
          <p>Geen domicilie mogelijk</p>
        </div>
        <div className="sub">
          <h2>Jaarabonnement</h2>
          <span className="cost">
            <h2>€ 350</h2>
          </span>
          <p>Inclusief voeding-en trainingsschema's op maat</p>
          <p>Geen domicilie mogelijk</p>
        </div>
      </div>
    </div>
  );
}
