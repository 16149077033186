import React from "react";

export default function About() {
  return (
    <div className="about-container" id="about">
      <div className="section1">
        <h1>powerfactory</h1>
        <article>
          Train met de beste! <span>POWERFACTORY</span> verandert de levens van
          inwoners van Aarschot sinds de oprichting in 1989. Fitness is geen
          hobby maar een manier van leven. We hebben onze sportschool opgericht
          als een tweede thuis voor al onze klanten. <br /> <br />
          Of je nu elke dag oefent of wanneer je nog nooit in een sportschool
          bent geweest, <span>POWERFACTORY</span> kan jou helpen om de nieuwe
          jij vorm te geven. We willen jouw volgende trainingspartner zijn. Bel
          ons vandaag nog!
        </article>
      </div>
    </div>
  );
}
