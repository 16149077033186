import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Gallery() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slideToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 3,
    },
    laptop: {
      breakpoint: { max: 1400, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const array = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
  ];
  const images = array.map((image) => {
    return (
      <img
        key={image}
        src={require(`../assets/Gallerij/${image}.jpeg`)}
        alt="gymfotos"
      />
    );
  });
  return (
    <div className="gallery-container">
      <div className="section2">
        <div className="items">
          <div className="item">
            <h1>Top kwaliteit apparatuur voor maximale gains</h1>
          </div>
        </div>
      </div>
      <Carousel
        responsive={responsive}
        swipeable={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
      >
        {images.map((image) => (
          <div className="card">{image}</div>
        ))}
      </Carousel>
    </div>
  );
}
