import React from "react";

export default function Banner() {
  return (
    <div className="banner-container" id="home">
      <h1>
        Walk away from every workout feeling proud, accomplished, and strong as
        hell
      </h1>
      <button>
        <a href="mailto:coach@powerfactory.be">Contacteer mij</a>
      </button>
    </div>
  );
}
